<template>
	<div class="list-tool-bar">
		<el-button-group class="action">
			<el-button @click="emit('onCreate')" type="primary">创建</el-button>
			<el-button type="primary" plain @click="emit('onExport')">导出</el-button>
		</el-button-group>
		<div class="search">
			<div class="search_item">
				<el-select 
					v-model="searchData.filter_project_id" 
					filterable 
					@change="doSearch"
					clearable
					placeholder="选择项目">
					<el-option
						v-for="(item, index) in projectList"
						:key="item.id"
						:label="item.name"
						:value="item.id">
					</el-option>
				</el-select>
			</div>
			<div class="search_item" v-if="$userRole(['SuperAdmin', 'GeneralAdmin'])">
				<el-select 
					v-model="searchData.filter_sales_user_id" 
					filterable 
					@change="doSearch"
					clearable
					placeholder="销售人员">
					<el-option
						v-for="(item, index) in salesList"
						:key="item.id"
						:label="item.code"
						:value="item.id">
					</el-option>
				</el-select>
			</div>
			<div class="search_item" v-if="$userRole(['SuperAdmin', 'GeneralAdmin', 'SalesMan'])">
				<el-select 
					v-model="searchData.filter_enterprise_id" 
					filterable 
					@change="doSearch"
					clearable
					placeholder="企业用户">
					<el-option
						v-for="(item, index) in enterpriseList"
						:key="item.id"
						:label="item.code"
						:value="item.id">
					</el-option>
				</el-select>
			</div>
			<el-button style="margin-left: 10px;" @click="emit('getList')">刷新</el-button>
		</div>
	</div>
</template>
<script setup>
	import { ref, onMounted, getCurrentInstance } from 'vue'
	import { enterpriseManagement, userManagement, AuthManagement } from '@/utils/api'

	const emit = defineEmits(['getList', 'onExport', 'onCreate'])
	const enterpriseList = ref([])
	const salesList = ref([])
	const projectList = ref([])
	const date = ref([])
	const { proxy } = getCurrentInstance()
	const searchData = ref({
		page:1
	})

	// 获取项目列表
	const getOrderList = () => {
		AuthManagement.ProjectList({
			is_paginate: false
		}).then(res => {
			projectList.value = res.items;
		})
	}

	// 获取企业列表
	const getEnterprise = () => {
		enterpriseManagement.EnterpriseList({
			is_paginate: false
		}).then(res => {
			enterpriseList.value = res.items;
		})
	}

	// 获取销售列表
	const getSales = () => {
		userManagement.roleUserList({
			filter_role_alias:'SalesMan',
			is_paginate: false
		}).then(res => {
			salesList.value = res.items;
		})
	}

	onMounted(() => {
		if(proxy.$userRole(['SuperAdmin', 'GeneralAdmin', 'SalesMan'])){
			getEnterprise();
		}
		if(proxy.$userRole(['SuperAdmin', 'GeneralAdmin'])){
			getSales()
		}
		getOrderList()
	})

	// 搜索事件
	const doSearch = () => {
		for(let key in searchData.value){
			if(searchData.value[key] === ''){
				delete searchData.value[key]
			}
		}
		emit('getList',searchData.value)
	}
</script>
