<template>
  <div class="main">
    <!-- 搜索栏 -->
    <ListToolBar @getList="getList" @onCreate="onCreate" @onExport="onExport" />
    <Table ref="table" />
    <!-- 表单 -->
    <Form ref="form" @getList="getList" />
  </div>
</template>

<script setup>
import ListToolBar from './components/ListToolBar'
import Form from './components/Form'
import { AuthManagement } from '@/utils/api'
import { onMounted, ref, getCurrentInstance, inject } from 'vue'
import { CsvExport } from '@/utils/format'
import { useRoute } from 'vue-router'
import Table from './components/table'

const route = useRoute()
const form = ref()
const t = inject('t')
const table = ref()
const searchData = ref({})
const { proxy } = getCurrentInstance()

// 创建
const onCreate = () => {
  if (!!!form.value) return
  form.value.openWin()
}

// 导出
const onExport = () => {
  (new CsvExport(
    AuthManagement.AssignmentList,
    searchData.value,
    (item) => {
      return {
        [t('table.creation_time')]: item.created_at || '-',
        [t('assign.license_code')]: item.id || '-',
        [t('assign.project')]: item.project ? item.project.name : '-',
        [t('product.product_name')]: item.project ? item.project.product ? item.project.product.name : '-' : '-',
        [t('order.order_code')]: item.order.code || '-',
        [t('order.order_type')]: proxy.$formatType(scope.order.category_id, scope.order.params.service_type === 'failback' ? t('order.failback') : '') || '-',
        [t('order.allocation_count')]: `${item.content.hosts ? proxy.$numberToCurrencyNo(item.content.hosts) + t('unit.ge') : '-'}`,
        [t('order.order_cycle')]: `${item.order.use_period ? proxy.$numberToCurrencyNo(item.order.use_period) + (Number(item.order.use_period) > 1 ? t('system.days') : t('system.day')) : '-'}`,
        [t('order.expiration_time')]: item.expired_at || '-',
        [t('assign.license_key')]: item.lic_key || '-',
        [t('assign.issuse_mail')]: item.project ? item.project.mail : '-'
      }
    },
    t('routes.assignment'),
  )).execute()
}

const getList = async () => {
  await nextTick()
  table.value.getList()
}
onMounted(() => {
  getList()
})
</script>